import { type SerializedFramework } from '#app/types/Framework.ts'
import {
	getAboutMeProfilePicture,
	getFrameworkImgSrc,
} from '#app/utils/misc.tsx'
import Link from '../button/index.tsx'
import { CVIcon } from '../icons/cv-icon'
import { LinkedInIcon } from '../icons/linkedin-icon'

export type ImageAssetType = {
	title: string
	url: string
	altText?: string
}

type AboutMeType = {
	profilePhoto?: Partial<ImageAssetType>
	body: string
	header?: string
	subHeader?: string
	cvUrl?: string
	linkedinUrl?: string
	frameworks?: SerializedFramework[]
}

const chunkArray = (array: SerializedFramework[], size: number) => {
	const result = []
	for (let i = 0; i < array.length; i += size) {
		result.push(array.slice(i, i + size))
	}
	return result
}

export function AboutMeComponent(aboutMe: AboutMeType) {
	const frameworksChunks = chunkArray(aboutMe?.frameworks ?? [], 8)

	return aboutMe ? (
		<>
			<div className="text-primary-dark z-30 mb-10 mt-20 flex grow items-center dark:text-white">
				<div>
					<span className="text-4xl tracking-tight">Hello there</span>
					<p className="text-6xl tracking-tighter">{aboutMe?.header},</p>
					<p className="text-6xl tracking-tighter">{aboutMe?.subHeader}.</p>
				</div>
			</div>
			<div className="min-h-inherit flex max-w-5xl flex-col">
				<div className="flex h-full flex-col justify-around md:flex-row md:justify-evenly">
					<div className="mr-2 flex h-full flex-col md:justify-center">
						<div className="flex flex-col items-center md:flex-row md:items-start">
							{aboutMe.profilePhoto ? (
								<img
									className="mb-6 h-[200px] w-[200px] rounded-full fill-white md:m-3 md:mt-0"
									src={aboutMe.profilePhoto.url ?? getAboutMeProfilePicture()}
									alt={aboutMe.profilePhoto.altText ?? ''}
								/>
							) : null}
							<div
								dangerouslySetInnerHTML={{ __html: aboutMe.body }}
								className="max-w-3xl p-2 text-center text-lg md:text-left"
							/>
						</div>

						{frameworksChunks.map((chunk, index) => {
							const keyName = `framework-chunk-${index}`
							return (
								<ul
									key={keyName}
									className="mb-2 mt-2 flex w-full flex-wrap items-center justify-center"
								>
									{chunk.map((framework: any) => (
										<li key={framework.name}>
											<img
												className="m-3 w-10 dark:brightness-0 dark:invert"
												src={getFrameworkImgSrc(framework.imageId ?? '')}
												alt={framework.name}
											/>
										</li>
									))}
								</ul>
							)
						})}
					</div>
				</div>
				<div className="mt-4 flex justify-center">
					<Link
						small
						target="_new"
						href={aboutMe.linkedinUrl ?? ''}
						className="text-primary-dark text-center uppercase dark:text-white"
						iconPosition="right"
						icon={<LinkedInIcon />}
					>
						LinkedIn
					</Link>
					<Link
						target="_new"
						small
						href={aboutMe.cvUrl ?? ''}
						className="text-primary-dark text-center uppercase dark:text-white"
						iconPosition="right"
						icon={<CVIcon />}
					>
						Curriculum
					</Link>
				</div>
			</div>
		</>
	) : null
}
